.about__container,
.info__list,
.stats,
.resume__container{
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
}

.info__list{
    row-gap: 20px;
    margin-bottom: 32px;
}

.section__subtitle{
    font-size: var(--h3-font-size);
    font-weight: var(--weight-600);
    margin-bottom: 20px;
}

.info__list .info__title,
.info__list .info__description{
    font-size: var(--small-font-size);
}

.info__list .info__description{
    color: var(--title-color);
    font-weight: var(--weight-600);
}

.info__description a{
    color: var(--title-color);
}

.info__description a:hover{
    opacity: 0.8;
}

.stats__box{
    border: 1px solid var(--border-color);
    padding: 20px 30px 25px;
    border-radius: 5px;
}

.stats__no{
    color: var(--first-color);
    font-size: var(--h1-font-size);
    line-height: 1.2;
}

.stats__title{
    font-size: var(--small-font-size);
    line-height: 1.6;
    padding-left: 45px;
    position: relative;
}

.stats__title::before{
    content: '';
    position: absolute;
    left: 0;
    top: 25%;
    width: 30px;
    height: 1px;
    background-color: var(--title-color);
}

.separator{
    border-top: 1px solid var(--border-color);
    max-width: 40%;
    margin: 72px auto 56px;
}

.subtitle__center{
    text-align: center;
    margin-bottom: 50px;
}

.skills__container{
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    row-gap: 48px;
}

.progress__circle{
    width: 120px;
    height: 120px;
}

.CircularProgressbar-path{
    stroke: var(--first-color) !important;
    stroke-linecap: butt !important;
}

.CircularProgressbar-trail{
    stroke: var(--container-color) !important;
}

.CircularProgressbar-text{
    fill: var(--title-color) !important;
    font-family: var(--seconde-font);
    font-size: var(--large-font-size) !important;
}

.skills__title{
    font-family: var(--body-font);
    font-size: var(--normal-font-size) !important;
    font-weight: var(--weight-400);
    text-align: center;
    margin-top: 24px;
}

.resume__item{
    position: relative;
    padding-left: 60px;
}

.resume__item:not(:last-child){
    margin-bottom: 50px;
}

.resume__item::before{
    content: '';
    position: absolute;
    inset-block: 0;
    left: 20px;
    border-left: 1px solid var(--border-color);
}

.resume__icon{
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--first-color);
    color: var(--text-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
}

.resume__date{
    background-color: var(--container-color);
    padding: 2px 10px;
    border-radius: 20px;
    font-size: var(--tiny-font-size);
    font-weight: var(--weight-600);
}

.resume__subtitle{
    font-size: var(--large-font-size);
    font-weight: var(--weight-500);
    margin-block: 20px 10px;
}

.resume__subtitle span{
    color: var(--text-color);
    font-family: var(--body-font);
    font-size: var(--small-font-size);
    font-weight: var(--weight-600);
    position: relative;
    padding-left: 26px;
}

.resume__subtitle span::before{
    content: '';
    position: absolute;
    left: 7px;
    top: 9px;
    width: 10px;
    height: 2px;
    background-color: var(--text-color);
}

.resume__description{
    font-size: var(--small-font-size);
    line-height: 1.6;
}

h5.card-title{
    color: black;
}

/* Media query for tablets */
@media (max-width: 768px) {
    .about__container,
    .info__list,
    .stats,
    .resume__container {
      grid-template-columns: 1fr; /* Change to a single column layout */
    }
  
    .info__list {
      text-align: center; /* Center-align the personal info items */
    }
  
    .separator {
      max-width: 100%; /* Full-width separator */
      margin: 20px auto 16px; /* Adjust the margin */
    }
  
    .skills__container {
      grid-template-columns: 1fr; /* Change to a single column layout */
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 480px) {
    .info__list {
      row-gap: 16px; /* Reduce gap between personal info items */
      text-align: left;
    }

    .stats__box{
        margin-top: 10px;
    }

    .resume__item {
      padding-left: 20px; /* Reduce left padding */
    }
  
    .resume__subtitle {
      font-size: var(--normal-font-size); /* Adjust font size */
      margin-block: 12px 8px; /* Adjust margin */
    }

    span.resume__date{
        margin-left: 20px;
    }

    .resume__item::before{
        content: '';
        position: absolute;
        inset-block: 0;
        left: 15px;
        border-left: 1px solid var(--border-color);
    }

    h3.resume__subtitle{
        padding: 7px;
    }

    .resume__data{
        margin-top: 40px;
    }

    .progress__circle{
        width: 120px;
        height: 120px;
    }

    .skills__title{
        margin-top: 2px;
        margin-bottom: 10px;
    }

    .progress__box{
        display: inline-block;
        margin-left: 30px;     
    }

    .info__list{
        row-gap: 20px;
        margin-bottom: 32px;
        margin-left: -31px; 
    }
    .button{
        margin-left: 60px; 
    }

  }

  
  
  