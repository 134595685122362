.contact__container{
    grid-template-columns: 4fr 8fr;
}

.contact__title{
    font-size: var(--h3-font-size);
    font-weight: var(--weight-600);
    margin-bottom: 1rem;
}

.contact__description{
    font-size: var(--small-font-size);
    line-height: 1.6;
}

.contact__description,
.contact__info .info__item{
    margin-bottom: 20px;
}

.contact__info .info__item{
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.contact__info .info__icon{
    color: var(--first-color);
    font-size: var(--h2-font-size);
}

.contact__info .info__title{
    font-size: var(--small-font-size);
}

.contact__info .info__desc{
    font-family: var(--body-font);
    font-size: var(--small-font-size);
    font-weight: var(--weight-500);
}

.contact__socials{
    display: flex;
    column-gap: 16px;
}

.contact__social-link{
    background-color: var(--container-color);
    color: var(--title-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    transition: all 0.3s ease;
}

.contact__social-link:hover{
    background-color: var(--first-color);
    color: var(--text-white);
}

.form__input-group{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
}

.form__input-div{
    margin-bottom: 30px;
}

.form__control{
    border: 1px solid var(--container-color);
    background-color: var(--container-color);
    color: var(--title-color);
    width: 100%;
    padding: 12px 24px;
    border-radius: 30px;
    font-size: var(--small-font-size);
    transition: all 0.3s ease;
}

.form__control:focus{
    border-color: var(--first-color);
}

.textarea{
    resize: none;
    height: 160px;
}

.contact__button-icon{
    font-size: var(--h3-font-size);
    line-height: 64px;
}

span.button__icon{
    background-color: var(--first-color);
    position: absolute;
    inset-block: -2px;
    right: 0;
    width: 55px;
    border-radius: 50%;
    color: var(--text-white);
    font-size: var(--large-font-size);
    text-align: center;
    line-height: 55px;
}

div#alert{
    color: aquamarine;
    position: absolute;
    width: 500px;
    text-align: center;
    left: 500px;
    margin-top: 5px;
}

/* Media query for mobile devices */
@media (max-width: 480px) {
    .grid{
        display: block;
    }
    
    .form__input-group {
      grid-template-columns: 1fr; /* Change to a single column layout */
      margin-top: 20px;
    }

    button.buttonc{
        margin-left: 57px;
    }
  
    .form__input-div {
      margin-bottom: 20px; /* Reduce margin for better spacing */
    }
    /* .buttonc{
        display: inline-block;
        color: var(--title-color);
        border: 1px solid var(--first-color);
        padding: 15px 70px 15px 35px;
        border-radius: 35px;
        font-family: var(--second-font);
        font-size: var(--small-font-size);
        font-weight: var(--weight-500);
        position: relative;
        overflow: hidden;
        transition: all 0.25s ease-in-out;
        
    }
    
    .buttonc:hover{
        color: var(--text-white);
    }
    
    .buttonc::before{
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--first-color);
        transform: translateX(100%);
        z-index: -1;
        transition: all 0.3s ease-out;
    }
    
    .buttonc:hover::before{
        transform: translateY(0);
    }
    
    .buttonc__icon{
        background-color: var(--first-color);
        position: absolute;
        inset-block: -2px;
        right: 0;
        width: 55px;
        border-radius: 50%;
        color: var(--text-white);
        font-size: var(--large-font-size);
        text-align: center;
        line-height: 60px;
    } */

    div#alert{
        color: aquamarine;
        position: fixed;
        width: 100%;
        text-align: center;
        left: 0;
        margin-top: 55px;
    }

    .section__title{
        font-size: 46px
    }
  }